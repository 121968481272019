/* http://meyerweb.com/eric/tools/css/reset/
   v2.0-modified | 20110126
   License: none (public domain)
*/
@import url("https://fonts.googleapis.com/css?family=PT+Sans:400,700&display=swap");
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline; }

/* make sure to set some focus styles for accessibility */
:focus {
  outline: 0; }

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block; }

body {
  line-height: 1; }

ol, ul {
  list-style: none; }

blockquote, q {
  quotes: none; }

blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

input[type=search]::-webkit-search-cancel-button,
input[type=search]::-webkit-search-decoration,
input[type=search]::-webkit-search-results-button,
input[type=search]::-webkit-search-results-decoration {
  -webkit-appearance: none;
  -moz-appearance: none; }

input[type=search] {
  -webkit-appearance: none;
  -moz-appearance: none;
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box; }

textarea {
  overflow: auto;
  vertical-align: top;
  resize: vertical; }

/**
 * Correct `inline-block` display not defined in IE 6/7/8/9 and Firefox 3.
 */
audio,
canvas,
video {
  display: inline-block;
  *display: inline;
  *zoom: 1;
  max-width: 100%; }

/**
 * Prevent modern browsers from displaying `audio` without controls.
 * Remove excess height in iOS 5 devices.
 */
audio:not([controls]) {
  display: none;
  height: 0; }

/**
 * Address styling not present in IE 7/8/9, Firefox 3, and Safari 4.
 * Known issue: no IE 6 support.
 */
[hidden] {
  display: none; }

/**
 * 1. Correct text resizing oddly in IE 6/7 when body `font-size` is set using
 *    `em` units.
 * 2. Prevent iOS text size adjust after orientation change, without disabling
 *    user zoom.
 */
html {
  font-size: 100%;
  /* 1 */
  -webkit-text-size-adjust: 100%;
  /* 2 */
  -ms-text-size-adjust: 100%;
  /* 2 */ }

/**
 * Address `outline` inconsistency between Chrome and other browsers.
 */
a:focus {
  outline: thin dotted; }

/**
 * Improve readability when focused and also mouse hovered in all browsers.
 */
a:active,
a:hover {
  outline: 0; }

/**
 * 1. Remove border when inside `a` element in IE 6/7/8/9 and Firefox 3.
 * 2. Improve image quality when scaled in IE 7.
 */
img {
  border: 0;
  /* 1 */
  -ms-interpolation-mode: bicubic;
  /* 2 */ }

/**
 * Address margin not present in IE 6/7/8/9, Safari 5, and Opera 11.
 */
figure {
  margin: 0; }

/**
 * Correct margin displayed oddly in IE 6/7.
 */
form {
  margin: 0; }

/**
 * Define consistent border, margin, and padding.
 */
fieldset {
  border: 1px solid #c0c0c0;
  margin: 0 2px;
  padding: 0.35em 0.625em 0.75em; }

/**
 * 1. Correct color not being inherited in IE 6/7/8/9.
 * 2. Correct text not wrapping in Firefox 3.
 * 3. Correct alignment displayed oddly in IE 6/7.
 */
legend {
  border: 0;
  /* 1 */
  padding: 0;
  white-space: normal;
  /* 2 */
  *margin-left: -7px;
  /* 3 */ }

/**
 * 1. Correct font size not being inherited in all browsers.
 * 2. Address margins set differently in IE 6/7, Firefox 3+, Safari 5,
 *    and Chrome.
 * 3. Improve appearance and consistency in all browsers.
 */
button,
input,
select,
textarea {
  font-size: 100%;
  /* 1 */
  margin: 0;
  /* 2 */
  vertical-align: baseline;
  /* 3 */
  *vertical-align: middle;
  /* 3 */ }

/**
 * Address Firefox 3+ setting `line-height` on `input` using `!important` in
 * the UA stylesheet.
 */
button,
input {
  line-height: normal; }

/**
 * Address inconsistent `text-transform` inheritance for `button` and `select`.
 * All other form control elements do not inherit `text-transform` values.
 * Correct `button` style inheritance in Chrome, Safari 5+, and IE 6+.
 * Correct `select` style inheritance in Firefox 4+ and Opera.
 */
button,
select {
  text-transform: none; }

/**
 * 1. Avoid the WebKit bug in Android 4.0.* where (2) destroys native `audio`
 *    and `video` controls.
 * 2. Correct inability to style clickable `input` types in iOS.
 * 3. Improve usability and consistency of cursor style between image-type
 *    `input` and others.
 * 4. Remove inner spacing in IE 7 without affecting normal text inputs.
 *    Known issue: inner spacing remains in IE 6.
 */
button,
html input[type="button"],
input[type="reset"],
input[type="submit"] {
  -webkit-appearance: button;
  /* 2 */
  cursor: pointer;
  /* 3 */
  *overflow: visible;
  /* 4 */ }

/**
 * Re-set default cursor for disabled elements.
 */
button[disabled],
html input[disabled] {
  cursor: default; }

/**
 * 1. Address box sizing set to content-box in IE 8/9.
 * 2. Remove excess padding in IE 8/9.
 * 3. Remove excess padding in IE 7.
 *    Known issue: excess padding remains in IE 6.
 */
input[type="checkbox"],
input[type="radio"] {
  box-sizing: border-box;
  /* 1 */
  padding: 0;
  /* 2 */
  *height: 13px;
  /* 3 */
  *width: 13px;
  /* 3 */ }

/**
 * 1. Address `appearance` set to `searchfield` in Safari 5 and Chrome.
 * 2. Address `box-sizing` set to `border-box` in Safari 5 and Chrome
 *    (include `-moz` to future-proof).
 */
input[type="search"] {
  -webkit-appearance: textfield;
  /* 1 */
  -moz-box-sizing: content-box;
  -webkit-box-sizing: content-box;
  /* 2 */
  box-sizing: content-box; }

/**
 * Remove inner padding and search cancel button in Safari 5 and Chrome
 * on OS X.
 */
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

/**
 * Remove inner padding and border in Firefox 3+.
 */
button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0; }

/**
 * 1. Remove default vertical scrollbar in IE 6/7/8/9.
 * 2. Improve readability and alignment in all browsers.
 */
textarea {
  overflow: auto;
  /* 1 */
  vertical-align: top;
  /* 2 */ }

/**
 * Remove most spacing between table cells.
 */
table {
  border-collapse: collapse;
  border-spacing: 0; }

html,
button,
input,
select,
textarea {
  color: #222; }

::-moz-selection {
  background: #b3d4fc;
  text-shadow: none; }

::selection {
  background: #b3d4fc;
  text-shadow: none; }

img {
  vertical-align: middle; }

fieldset {
  border: 0;
  margin: 0;
  padding: 0; }

textarea {
  resize: vertical; }

.chromeframe {
  margin: 0.2em 0;
  background: #ccc;
  color: #000;
  padding: 0.2em 0; }

b, strong {
  font-weight: bold; }

.c-separation-line {
  position: relative;
  width: 100%;
  text-align: center; }
  .c-separation-line span {
    text-align: center;
    background: #fff;
    display: inline-block;
    padding: 1px 10px;
    z-index: 10;
    position: relative;
    color: #aaa;
    font-size: 11px; }
  .c-separation-line:after {
    content: '';
    height: 1px;
    width: 100%;
    background: #eee;
    position: absolute;
    left: 0;
    top: 9px; }

.cursor--pointer {
  cursor: pointer; }

@font-face {
  font-family: 'Montserrat';
  font-weight: 400;
  src: url(./fonts/Montserrat-Regular.ttf) format("truetype"); }

@font-face {
  font-family: 'Montserrat';
  font-weight: 500;
  src: url(./fonts/Montserrat-Medium.ttf) format("truetype"); }

@font-face {
  font-family: 'Montserrat';
  font-weight: 700;
  src: url(./fonts/Montserrat-Bold.ttf) format("truetype"); }

body {
  font-family: 'PT Sans', sans-serif; }

h1 {
  font-family: 'PT Sans', sans-serif;
  font-weight: 700; }

h2 {
  font-size: 21px;
  margin-bottom: 18px; }

p.p--color-1 {
  color: #666666; }

p.p--small {
  font-size: 13px; }

label {
  margin-bottom: 7px; }

.c-label {
  font-size: 13px; }
  .c-label--uppercased {
    text-transform: uppercase; }
  .c-label--color-1 {
    color: #222222; }
  .c-label--color-2 {
    color: var(--color-primary); }
    .c-label--color-2:hover {
      color: var(--color-primary); }

.display-flex {
  display: flex; }
  .display-flex--align--center {
    align-items: center; }
  .display-flex--align-right {
    justify-content: flex-end; }

.is-aligned-right {
  text-align: right !important; }

:root {
  --container-max-width: 1200px; }

*, ::after, ::before {
  box-sizing: border-box; }

.container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
  max-width: var(--container-max-width); }
  .container--force {
    margin-right: 0;
    margin-left: 0;
    padding-right: 0;
    padding-left: 0;
    max-width: var(--container-max-width) !important; }

@media (min-width: 1400px) {
  :root {
    --container-max-width: 1300px; } }

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px; }

.justify-content-end {
  justify-content: flex-end !important; }

.align-items-end {
  align-items: flex-end !important; }

.align-items-center {
  align-items: center !important; }

.col,
[class^="col-"] {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px; }

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%; }

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: 100%; }

.ml-auto {
  margin-left: auto; }

.mr-auto, .mx-auto {
  margin-right: auto !important; }

.ml-auto, .mx-auto {
  margin-left: auto !important; }

.col-1 {
  flex-basis: 8.33333%; }

.col-fixed-1 {
  width: 100px; }

.col-2 {
  flex-basis: 16.66667%; }

.col-fixed-2 {
  width: 200px; }

.col-3 {
  flex-basis: 25%; }

.col-fixed-3 {
  width: 300px; }

.col-4 {
  flex-basis: 33.33333%; }

.col-fixed-4 {
  width: 400px; }

.col-5 {
  flex-basis: 41.66667%; }

.col-fixed-5 {
  width: 500px; }

.col-6 {
  flex-basis: 50%; }

.col-fixed-6 {
  width: 600px; }

.col-7 {
  flex-basis: 58.33333%; }

.col-fixed-7 {
  width: 700px; }

.col-8 {
  flex-basis: 66.66667%; }

.col-fixed-8 {
  width: 800px; }

.col-9 {
  flex-basis: 75%; }

.col-fixed-9 {
  width: 900px; }

.col-10 {
  flex-basis: 83.33333%; }

.col-fixed-10 {
  width: 1000px; }

.col-11 {
  flex-basis: 91.66667%; }

.col-fixed-11 {
  width: 1100px; }

.col-12 {
  flex-basis: 100%; }

.col-fixed-12 {
  width: 1200px; }

.col-1 {
  flex-basis: 8.33333%;
  max-width: 8.33333%; }

.offset-1 {
  margin-left: 8.33333%; }

.col-2 {
  flex-basis: 16.66667%;
  max-width: 16.66667%; }

.offset-2 {
  margin-left: 16.66667%; }

.col-3 {
  flex-basis: 25%;
  max-width: 25%; }

.offset-3 {
  margin-left: 25%; }

.col-4 {
  flex-basis: 33.33333%;
  max-width: 33.33333%; }

.offset-4 {
  margin-left: 33.33333%; }

.col-5 {
  flex-basis: 41.66667%;
  max-width: 41.66667%; }

.offset-5 {
  margin-left: 41.66667%; }

.col-6 {
  flex-basis: 50%;
  max-width: 50%; }

.offset-6 {
  margin-left: 50%; }

.col-7 {
  flex-basis: 58.33333%;
  max-width: 58.33333%; }

.offset-7 {
  margin-left: 58.33333%; }

.col-8 {
  flex-basis: 66.66667%;
  max-width: 66.66667%; }

.offset-8 {
  margin-left: 66.66667%; }

.col-9 {
  flex-basis: 75%;
  max-width: 75%; }

.offset-9 {
  margin-left: 75%; }

.col-10 {
  flex-basis: 83.33333%;
  max-width: 83.33333%; }

.offset-10 {
  margin-left: 83.33333%; }

.col-11 {
  flex-basis: 91.66667%;
  max-width: 91.66667%; }

.offset-11 {
  margin-left: 91.66667%; }

.col-12 {
  flex-basis: 100%;
  max-width: 100%; }

.offset-12 {
  margin-left: 100%; }

@media (min-width: 576px) {
  .col-sm-1 {
    flex-basis: 8.33333%;
    max-width: 8.33333%; } }

@media (min-width: 576px) {
  .offset-sm-1 {
    margin-left: 8.33333%; } }

@media (min-width: 576px) {
  .col-sm-2 {
    flex-basis: 16.66667%;
    max-width: 16.66667%; } }

@media (min-width: 576px) {
  .offset-sm-2 {
    margin-left: 16.66667%; } }

@media (min-width: 576px) {
  .col-sm-3 {
    flex-basis: 25%;
    max-width: 25%; } }

@media (min-width: 576px) {
  .offset-sm-3 {
    margin-left: 25%; } }

@media (min-width: 576px) {
  .col-sm-4 {
    flex-basis: 33.33333%;
    max-width: 33.33333%; } }

@media (min-width: 576px) {
  .offset-sm-4 {
    margin-left: 33.33333%; } }

@media (min-width: 576px) {
  .col-sm-5 {
    flex-basis: 41.66667%;
    max-width: 41.66667%; } }

@media (min-width: 576px) {
  .offset-sm-5 {
    margin-left: 41.66667%; } }

@media (min-width: 576px) {
  .col-sm-6 {
    flex-basis: 50%;
    max-width: 50%; } }

@media (min-width: 576px) {
  .offset-sm-6 {
    margin-left: 50%; } }

@media (min-width: 576px) {
  .col-sm-7 {
    flex-basis: 58.33333%;
    max-width: 58.33333%; } }

@media (min-width: 576px) {
  .offset-sm-7 {
    margin-left: 58.33333%; } }

@media (min-width: 576px) {
  .col-sm-8 {
    flex-basis: 66.66667%;
    max-width: 66.66667%; } }

@media (min-width: 576px) {
  .offset-sm-8 {
    margin-left: 66.66667%; } }

@media (min-width: 576px) {
  .col-sm-9 {
    flex-basis: 75%;
    max-width: 75%; } }

@media (min-width: 576px) {
  .offset-sm-9 {
    margin-left: 75%; } }

@media (min-width: 576px) {
  .col-sm-10 {
    flex-basis: 83.33333%;
    max-width: 83.33333%; } }

@media (min-width: 576px) {
  .offset-sm-10 {
    margin-left: 83.33333%; } }

@media (min-width: 576px) {
  .col-sm-11 {
    flex-basis: 91.66667%;
    max-width: 91.66667%; } }

@media (min-width: 576px) {
  .offset-sm-11 {
    margin-left: 91.66667%; } }

@media (min-width: 576px) {
  .col-sm-12 {
    flex-basis: 100%;
    max-width: 100%; } }

@media (min-width: 576px) {
  .offset-sm-12 {
    margin-left: 100%; } }

@media (min-width: 768px) {
  .col-md-1 {
    flex-basis: 8.33333%;
    max-width: 8.33333%; } }

@media (min-width: 768px) {
  .offset-md-1 {
    margin-left: 8.33333%; } }

@media (min-width: 768px) {
  .col-md-2 {
    flex-basis: 16.66667%;
    max-width: 16.66667%; } }

@media (min-width: 768px) {
  .offset-md-2 {
    margin-left: 16.66667%; } }

@media (min-width: 768px) {
  .col-md-3 {
    flex-basis: 25%;
    max-width: 25%; } }

@media (min-width: 768px) {
  .offset-md-3 {
    margin-left: 25%; } }

@media (min-width: 768px) {
  .col-md-4 {
    flex-basis: 33.33333%;
    max-width: 33.33333%; } }

@media (min-width: 768px) {
  .offset-md-4 {
    margin-left: 33.33333%; } }

@media (min-width: 768px) {
  .col-md-5 {
    flex-basis: 41.66667%;
    max-width: 41.66667%; } }

@media (min-width: 768px) {
  .offset-md-5 {
    margin-left: 41.66667%; } }

@media (min-width: 768px) {
  .col-md-6 {
    flex-basis: 50%;
    max-width: 50%; } }

@media (min-width: 768px) {
  .offset-md-6 {
    margin-left: 50%; } }

@media (min-width: 768px) {
  .col-md-7 {
    flex-basis: 58.33333%;
    max-width: 58.33333%; } }

@media (min-width: 768px) {
  .offset-md-7 {
    margin-left: 58.33333%; } }

@media (min-width: 768px) {
  .col-md-8 {
    flex-basis: 66.66667%;
    max-width: 66.66667%; } }

@media (min-width: 768px) {
  .offset-md-8 {
    margin-left: 66.66667%; } }

@media (min-width: 768px) {
  .col-md-9 {
    flex-basis: 75%;
    max-width: 75%; } }

@media (min-width: 768px) {
  .offset-md-9 {
    margin-left: 75%; } }

@media (min-width: 768px) {
  .col-md-10 {
    flex-basis: 83.33333%;
    max-width: 83.33333%; } }

@media (min-width: 768px) {
  .offset-md-10 {
    margin-left: 83.33333%; } }

@media (min-width: 768px) {
  .col-md-11 {
    flex-basis: 91.66667%;
    max-width: 91.66667%; } }

@media (min-width: 768px) {
  .offset-md-11 {
    margin-left: 91.66667%; } }

@media (min-width: 768px) {
  .col-md-12 {
    flex-basis: 100%;
    max-width: 100%; } }

@media (min-width: 768px) {
  .offset-md-12 {
    margin-left: 100%; } }

@media (min-width: 992px) {
  .col-lg-1 {
    flex-basis: 8.33333%;
    max-width: 8.33333%; } }

@media (min-width: 992px) {
  .offset-lg-1 {
    margin-left: 8.33333%; } }

@media (min-width: 992px) {
  .col-lg-2 {
    flex-basis: 16.66667%;
    max-width: 16.66667%; } }

@media (min-width: 992px) {
  .offset-lg-2 {
    margin-left: 16.66667%; } }

@media (min-width: 992px) {
  .col-lg-3 {
    flex-basis: 25%;
    max-width: 25%; } }

@media (min-width: 992px) {
  .offset-lg-3 {
    margin-left: 25%; } }

@media (min-width: 992px) {
  .col-lg-4 {
    flex-basis: 33.33333%;
    max-width: 33.33333%; } }

@media (min-width: 992px) {
  .offset-lg-4 {
    margin-left: 33.33333%; } }

@media (min-width: 992px) {
  .col-lg-5 {
    flex-basis: 41.66667%;
    max-width: 41.66667%; } }

@media (min-width: 992px) {
  .offset-lg-5 {
    margin-left: 41.66667%; } }

@media (min-width: 992px) {
  .col-lg-6 {
    flex-basis: 50%;
    max-width: 50%; } }

@media (min-width: 992px) {
  .offset-lg-6 {
    margin-left: 50%; } }

@media (min-width: 992px) {
  .col-lg-7 {
    flex-basis: 58.33333%;
    max-width: 58.33333%; } }

@media (min-width: 992px) {
  .offset-lg-7 {
    margin-left: 58.33333%; } }

@media (min-width: 992px) {
  .col-lg-8 {
    flex-basis: 66.66667%;
    max-width: 66.66667%; } }

@media (min-width: 992px) {
  .offset-lg-8 {
    margin-left: 66.66667%; } }

@media (min-width: 992px) {
  .col-lg-9 {
    flex-basis: 75%;
    max-width: 75%; } }

@media (min-width: 992px) {
  .offset-lg-9 {
    margin-left: 75%; } }

@media (min-width: 992px) {
  .col-lg-10 {
    flex-basis: 83.33333%;
    max-width: 83.33333%; } }

@media (min-width: 992px) {
  .offset-lg-10 {
    margin-left: 83.33333%; } }

@media (min-width: 992px) {
  .col-lg-11 {
    flex-basis: 91.66667%;
    max-width: 91.66667%; } }

@media (min-width: 992px) {
  .offset-lg-11 {
    margin-left: 91.66667%; } }

@media (min-width: 992px) {
  .col-lg-12 {
    flex-basis: 100%;
    max-width: 100%; } }

@media (min-width: 992px) {
  .offset-lg-12 {
    margin-left: 100%; } }

@media (min-width: 1200px) {
  .col-xl-1 {
    flex-basis: 8.33333%;
    max-width: 8.33333%; } }

@media (min-width: 1200px) {
  .offset-xl-1 {
    margin-left: 8.33333%; } }

@media (min-width: 1200px) {
  .col-xl-2 {
    flex-basis: 16.66667%;
    max-width: 16.66667%; } }

@media (min-width: 1200px) {
  .offset-xl-2 {
    margin-left: 16.66667%; } }

@media (min-width: 1200px) {
  .col-xl-3 {
    flex-basis: 25%;
    max-width: 25%; } }

@media (min-width: 1200px) {
  .offset-xl-3 {
    margin-left: 25%; } }

@media (min-width: 1200px) {
  .col-xl-4 {
    flex-basis: 33.33333%;
    max-width: 33.33333%; } }

@media (min-width: 1200px) {
  .offset-xl-4 {
    margin-left: 33.33333%; } }

@media (min-width: 1200px) {
  .col-xl-5 {
    flex-basis: 41.66667%;
    max-width: 41.66667%; } }

@media (min-width: 1200px) {
  .offset-xl-5 {
    margin-left: 41.66667%; } }

@media (min-width: 1200px) {
  .col-xl-6 {
    flex-basis: 50%;
    max-width: 50%; } }

@media (min-width: 1200px) {
  .offset-xl-6 {
    margin-left: 50%; } }

@media (min-width: 1200px) {
  .col-xl-7 {
    flex-basis: 58.33333%;
    max-width: 58.33333%; } }

@media (min-width: 1200px) {
  .offset-xl-7 {
    margin-left: 58.33333%; } }

@media (min-width: 1200px) {
  .col-xl-8 {
    flex-basis: 66.66667%;
    max-width: 66.66667%; } }

@media (min-width: 1200px) {
  .offset-xl-8 {
    margin-left: 66.66667%; } }

@media (min-width: 1200px) {
  .col-xl-9 {
    flex-basis: 75%;
    max-width: 75%; } }

@media (min-width: 1200px) {
  .offset-xl-9 {
    margin-left: 75%; } }

@media (min-width: 1200px) {
  .col-xl-10 {
    flex-basis: 83.33333%;
    max-width: 83.33333%; } }

@media (min-width: 1200px) {
  .offset-xl-10 {
    margin-left: 83.33333%; } }

@media (min-width: 1200px) {
  .col-xl-11 {
    flex-basis: 91.66667%;
    max-width: 91.66667%; } }

@media (min-width: 1200px) {
  .offset-xl-11 {
    margin-left: 91.66667%; } }

@media (min-width: 1200px) {
  .col-xl-12 {
    flex-basis: 100%;
    max-width: 100%; } }

@media (min-width: 1200px) {
  .offset-xl-12 {
    margin-left: 100%; } }

.m-0 {
  margin: 0rem !important; }

.mb-0 {
  margin-bottom: 0rem !important; }

.ml-0 {
  margin-left: 0rem !important; }

.mt-0 {
  margin-top: 0rem !important; }

.mr-0 {
  margin-right: 0rem !important; }

.m-1 {
  margin: 0.5rem !important; }

.mb-1 {
  margin-bottom: 0.5rem !important; }

.ml-1 {
  margin-left: 0.5rem !important; }

.mt-1 {
  margin-top: 0.5rem !important; }

.mr-1 {
  margin-right: 0.5rem !important; }

.m-2 {
  margin: 1rem !important; }

.mb-2 {
  margin-bottom: 1rem !important; }

.ml-2 {
  margin-left: 1rem !important; }

.mt-2 {
  margin-top: 1rem !important; }

.mr-2 {
  margin-right: 1rem !important; }

.m-3 {
  margin: 1.5rem !important; }

.mb-3 {
  margin-bottom: 1.5rem !important; }

.ml-3 {
  margin-left: 1.5rem !important; }

.mt-3 {
  margin-top: 1.5rem !important; }

.mr-3 {
  margin-right: 1.5rem !important; }

.m-4 {
  margin: 2rem !important; }

.mb-4 {
  margin-bottom: 2rem !important; }

.ml-4 {
  margin-left: 2rem !important; }

.mt-4 {
  margin-top: 2rem !important; }

.mr-4 {
  margin-right: 2rem !important; }

.m-5 {
  margin: 2.5rem !important; }

.mb-5 {
  margin-bottom: 2.5rem !important; }

.ml-5 {
  margin-left: 2.5rem !important; }

.mt-5 {
  margin-top: 2.5rem !important; }

.mr-5 {
  margin-right: 2.5rem !important; }

.pb-0 {
  padding-bottom: 0rem !important; }

.pt-0 {
  padding-top: 0rem !important; }

.pl-0 {
  padding-left: 0rem !important; }

.pr-0 {
  padding-right: 0rem !important; }

@media (min-width: 992px) {
  .pl-lg-0 {
    padding-left: 0rem !important; }
  .pr-lg-0 {
    padding-right: 0rem !important; } }

.pb-1 {
  padding-bottom: 0.5rem !important; }

.pt-1 {
  padding-top: 0.5rem !important; }

.pl-1 {
  padding-left: 0.5rem !important; }

.pr-1 {
  padding-right: 0.5rem !important; }

@media (min-width: 992px) {
  .pl-lg-1 {
    padding-left: 0.5rem !important; }
  .pr-lg-1 {
    padding-right: 0.5rem !important; } }

.pb-2 {
  padding-bottom: 1rem !important; }

.pt-2 {
  padding-top: 1rem !important; }

.pl-2 {
  padding-left: 1rem !important; }

.pr-2 {
  padding-right: 1rem !important; }

@media (min-width: 992px) {
  .pl-lg-2 {
    padding-left: 1rem !important; }
  .pr-lg-2 {
    padding-right: 1rem !important; } }

.pb-3 {
  padding-bottom: 1.5rem !important; }

.pt-3 {
  padding-top: 1.5rem !important; }

.pl-3 {
  padding-left: 1.5rem !important; }

.pr-3 {
  padding-right: 1.5rem !important; }

@media (min-width: 992px) {
  .pl-lg-3 {
    padding-left: 1.5rem !important; }
  .pr-lg-3 {
    padding-right: 1.5rem !important; } }

.pb-4 {
  padding-bottom: 2rem !important; }

.pt-4 {
  padding-top: 2rem !important; }

.pl-4 {
  padding-left: 2rem !important; }

.pr-4 {
  padding-right: 2rem !important; }

@media (min-width: 992px) {
  .pl-lg-4 {
    padding-left: 2rem !important; }
  .pr-lg-4 {
    padding-right: 2rem !important; } }

.pb-5 {
  padding-bottom: 2.5rem !important; }

.pt-5 {
  padding-top: 2.5rem !important; }

.pl-5 {
  padding-left: 2.5rem !important; }

.pr-5 {
  padding-right: 2.5rem !important; }

@media (min-width: 992px) {
  .pl-lg-5 {
    padding-left: 2.5rem !important; }
  .pr-lg-5 {
    padding-right: 2.5rem !important; } }

.c-flag {
  display: inline-block; }
  .c-flag--en-us {
    width: 22px;
    height: 16px;
    background-image: url("../images/flags.png");
    background-position: 0 0; }
  .c-flag--nb-no {
    width: 22px;
    height: 16px;
    background-image: url("../images/flags.png");
    background-position: 0 80px; }
  .c-flag--sv-se {
    width: 22px;
    height: 16px;
    background-image: url("../images/flags.png");
    background-position: 0 64px; }
  .c-flag--da-dk {
    width: 22px;
    height: 16px;
    background-image: url("../images/flags.png");
    background-position: 0 48px; }
  .c-flag--es-es {
    width: 22px;
    height: 16px;
    background-image: url("../images/flags.png");
    background-position: 0 32px; }
  .c-flag--ar-ar {
    width: 22px;
    height: 16px;
    background-image: url("../images/flags.png");
    background-position: 0 16px; }

:root {
  --login-background-image: url('../images/body-background.png'); }

.login-page {
  background-image: var(--login-background-image); }

.login-form {
  margin: 20px auto;
  max-width: 340px;
  width: 90vw; }
  @media (min-width: 576px) {
    .login-form {
      margin: 20px auto 40px; } }

@media (max-width: 370px) {
  .login-form .hide-on-mobile {
    display: none; } }

.m-module-container {
  background: #fff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2); }
  .m-module-container--with-padding {
    padding: 25px; }

.heading--color-1 {
  color: var(--color-primary); }

.heading--bold {
  font-weight: bold; }

.heading--small-mb {
  margin-bottom: 8px; }

.c-form-control {
  display: block;
  width: 100%;
  height: 40px;
  padding: 6px 12px;
  font-size: 14px;
  line-height: 1.42857143;
  color: #555;
  background-color: #fff;
  background-image: none;
  border: 1px solid #D8D8D8;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  -webkit-transition: border-color ease-in-out .15s, -webkit-box-shadow ease-in-out .15s;
  -o-transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
  transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s; }
  .c-form-control::placeholder {
    color: #D8D8D8; }

.c-btn {
  display: inline-block;
  padding: 9px 12px;
  margin-bottom: 0;
  font-size: 14px;
  font-weight: normal;
  line-height: 1.42857143;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  touch-action: manipulation;
  cursor: pointer;
  user-select: none;
  background-image: none;
  border: 1px solid transparent;
  width: 100%;
  transition: background 0.3s ease; }
  .c-btn--variant-1 {
    color: var(--btn-text-color-secondary);
    background: var(--btn-background-color-secondary); }
    .c-btn--variant-1:hover {
      background: hsl(var(--btn-background-color-secondary-h), var(--btn-background-color-secondary-s), calc(var(--btn-background-color-secondary-l) * 1)); }
  .c-btn--variant-id-porten {
    color: #fff;
    background: #4B78AF; }
    .c-btn--variant-id-porten:hover {
      background: #436c9d; }
  .c-btn--variant-nets {
    color: #fff;
    background: #005776; }
    .c-btn--variant-nets:hover {
      background: #00445d; }
  .c-btn--bold {
    font-weight: bold; }
  .c-btn img {
    max-height: 17px;
    margin-right: 5px; }

body {
  min-height: 100vh; }

.ie-warning {
  display: none;
  padding: 20px 30px;
  width: 530px;
  text-align: center; }
  .ie-warning.is-detected {
    display: block; }
  .ie-warning i {
    margin-bottom: 20px;
    font-size: 80px;
    color: #c30000; }
  .ie-warning p {
    color: #787878;
    line-height: 1.3; }
    .ie-warning p:nth-of-type(1) {
      font-weight: 600;
      font-size: 26px; }
    .ie-warning p:nth-of-type(2) {
      font-size: 26px; }
    .ie-warning p:nth-of-type(3) {
      font-size: 18px; }
  .ie-warning button {
    background-color: #c30000; }
    .ie-warning button:hover {
      background-color: #aa0000; }

.ie-warning--static {
  display: none;
  justify-content: center;
  align-items: center;
  padding: 20px 0;
  color: #c30000;
  background-color: #ffc3c3; }
  .ie-warning--static.is-detected {
    display: flex; }
  .ie-warning--static i {
    margin: 0 20px 0 0;
    font-size: 60px; }
  .ie-warning--static p {
    line-height: 1.3;
    margin-bottom: 4px; }
    .ie-warning--static p:nth-of-type(1) {
      font-weight: 600;
      font-size: 18px; }
    .ie-warning--static p:nth-of-type(2) {
      font-size: 18px; }
    .ie-warning--static p:nth-of-type(3) {
      margin: 0;
      font-size: 14px; }

.scroll-1 {
  overflow-y: auto; }
  .scroll-1::-webkit-scrollbar {
    width: 4px; }
  .scroll-1::-webkit-scrollbar-button {
    width: 0px;
    height: 0px; }
  .scroll-1::-webkit-scrollbar-thumb {
    background: #bfbfbf;
    border: 0px none #fff;
    border-radius: 0px; }
  .scroll-1::-webkit-scrollbar-track {
    background: #e1e1e1;
    border: 0px none #fff;
    border-radius: 0px; }
  .scroll-1::-webkit-scrollbar-corner {
    background: transparent; }

@media (min-width: 992px) {
  .hide-lg {
    display: none !important; } }

@media (min-width: 768px) {
  .hide-md {
    display: none !important; } }

@media (max-width: 768px) {
  .show-sm {
    display: none !important; } }

@media (max-width: 992px) {
  .show-md {
    display: none !important; } }

@media (max-width: 992px) {
  .show-lg {
    display: none !important; } }

@media (max-width: 992px) {
  .hide-m {
    display: none !important; } }

@media (min-width: 992px) {
  .hide-d {
    display: none !important; } }

.modal-close-btn {
  z-index: 1;
  color: #000; }

.error-field {
  margin: 6px 0 0 0;
  font-size: 12px;
  text-transform: uppercase;
  color: red; }

.warning-field {
  margin: 6px 0 0 0;
  font-size: 12px;
  text-transform: uppercase;
  color: #ffa500; }

.l-header {
  height: 60px;
  color: var(--header-text-color);
  background: var(--header-background-color); }
  .l-header .l-header__group {
    display: flex; }
  .l-header .l-header__group-item {
    display: flex;
    align-items: center;
    margin: 0 0 0 20px;
    padding: 0 0 0 20px;
    height: 60px;
    border-left: 1px solid #6fa29b; }
    .l-header .l-header__group-item:first-child {
      margin: 0;
      padding: 0;
      border: none; }
  .l-header .l-header__page-title {
    margin-left: 20px;
    color: var(--header-text-color);
    font-size: 20px;
    font-weight: 500;
    font-family: Montserrat; }
  .l-header a {
    text-decoration: none; }

.main {
  display: flex;
  flex-direction: column;
  min-height: 100vh; }
  .main.main--centered .body-content {
    flex: 1 1 auto;
    display: flex;
    align-items: center; }

.footer {
  margin-top: auto;
  display: flex;
  align-items: center;
  padding: 10px 0;
  height: 70px;
  background: var(--footer-background-color) !important;
  box-shadow: 0 -1px 6px rgba(0, 0, 0, 0.16); }
  .footer h2 {
    margin-bottom: 10px;
    font-size: 16px; }
  .footer .contact {
    display: flex;
    align-items: center;
    margin: 0;
    color: var(--footer-text-color);
    font-size: 16px; }
    .footer .contact i {
      margin: 0 5px 0 0;
      color: var(--footer-text-color);
      font-size: 16px; }
    .footer .contact a {
      color: var(--footer-text-color);
      font-size: 16px; }
    .footer .contact .email {
      margin: 0 40px 0 0; }
    .footer .contact .logo {
      margin-left: auto; }
      .footer .contact .logo img {
        height: 25px; }

.c-tab__header {
  display: flex;
  align-items: center;
  margin: 0 0 20px 0;
  height: 60px;
  background: #f8f8f8; }

.c-tab__nav {
  display: flex; }

.c-tab__nav-item {
  padding-left: 56px; }
  .c-tab__nav-item:first-child {
    padding-left: 0; }
  .c-tab__nav-item a {
    color: rgba(0, 0, 0, 0.7);
    font-size: 16px;
    text-decoration: none; }
  .c-tab__nav-item.active a {
    position: relative;
    color: #000; }
    .c-tab__nav-item.active a::before {
      content: '';
      position: absolute;
      bottom: 0;
      width: 100%;
      height: 1px;
      background: #00816d; }

.featured a {
  color: var(--color-primary);
  font-size: 14px;
  font-weight: 600; }

.expand-cell {
  cursor: pointer; }

.pagination .page-item {
  margin: 0 4px;
  transition: all 0.3 ease; }
  .pagination .page-item:last-child a {
    border-radius: 0; }
  .pagination .page-item.active a {
    color: #fff;
    background: var(--color-primary);
    border-radius: 0; }
    .pagination .page-item.active a:hover {
      color: #fff;
      background: var(--color-primary); }
  .pagination .page-item a {
    color: var(--color-primary);
    font-size: 12px;
    font-weight: 600;
    border: 1px solid var(--color-primary);
    border-radius: 0;
    transition: all 0.3 ease; }
    .pagination .page-item a:hover {
      background: rgba(0, 129, 109, 0.15);
      border-color: var(--color-primary);
      transition: all 0.3 ease; }

.c-module-container {
  position: relative;
  margin: 20px 0;
  min-height: 50px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  background: #fff; }
  .c-module-container .c-module-container__header {
    display: flex;
    align-items: center;
    padding: 0 16px;
    height: 50px;
    background: #f7f7f7; }
    .c-module-container .c-module-container__header .title {
      font-size: 14px;
      font-weight: 600;
      text-transform: uppercase; }
  .c-module-container .c-module-container__body {
    background: #fff;
    position: relative;
    min-height: 50px;
    padding: 16px; }

table tr th.sortable {
  position: relative;
  padding-right: 25px;
  white-space: nowrap; }
  table tr th.sortable .order {
    position: absolute;
    top: 50%;
    right: 5px;
    transform: translateY(-50%); }
